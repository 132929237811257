import {sendGetMsg, sendPutMsg} from "../../shared/api/SendMsg";
import {URLS} from "../../shared/const/Backend";

export const getUserInfo = (successHandler) => {
    sendGetMsg(URLS.USER,
        null,
        successHandler
    )
}

export const updateUser = (newUserData, successHandler) => {
    sendPutMsg(URLS.USER,
        newUserData,
        successHandler
    )
}

export const getFamily = (successHandler) => {
    sendGetMsg(URLS.FAMILY,
        null,
        successHandler
    )
}