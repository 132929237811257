import style from "../Login.module.css";
import {Button, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {useState} from "react";
import {registerUser} from "../../api/request";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

export const RegisterComp = ({buttonStyles, inputStyles}) => {
    const history = useNavigate()
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [role, setRole] = useState("")
    const [specialistId, setSpecialistId] = useState("")
    const [errors, setErrors] = useState({})

    const emptyCheck = () => {
        const newErrors = {}
        if (!login) {
            newErrors.login = "Логин обязателен"
        }
        if (!password) {
            newErrors.password = "Пароль обязателен"
        }
        if (password !== passwordConfirm) {
            newErrors.passwordConfirm = "Пароли не совпадают"
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const clearFields = () => {
        setLogin("")
        setPassword("")
        setPasswordConfirm("")
        setRole("")
        setSpecialistId("")
    }

    const Register = () => {
        if (emptyCheck()) {
            role === ""
                ?
                toast.error("Роль обязательна")
                :
                registerUser(login, password, role, specialistId, history, clearFields)
        }
    }

    return <div className={style.registerWrapper}>
        <div className={style.header}>Регистрация</div>
        <div className={style.inputField}>
            <TextField
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                size="small"
                error={!!errors.login}
                label={"Логин"}
                sx={inputStyles}
            />
        </div>
        <div className={style.inputField}>
            <TextField
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="small"
                error={!!errors.password}
                label={"Пароль"}
                sx={inputStyles}
            />
        </div>
        <div className={style.inputField}>
            <TextField
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                size="small"
                error={!!errors.passwordConfirm}
                label={"Пароль еще раз"}
                sx={inputStyles}
            />
        </div>
        <div className={style.radioWrapper}>
            <RadioGroup
                row
                value={role}
                onChange={(e) => {
                    setSpecialistId("")
                    setRole(e.target.value)}
                }
            >
                <FormControlLabel value="PARENT" control={<Radio />} label="Родитель" />
                <FormControlLabel value="SPECIALIST" control={<Radio />} label="Специалист" />
            </RadioGroup>
        </div>
        {role === 'PARENT' && (
            <div className={style.inputSpec}>
                <TextField
                    size="small"
                    value={specialistId}
                    onChange={(e) => setSpecialistId(e.target.value)}
                    label={"ИД Специалиста"}
                    sx={inputStyles}
                />
            </div>
        )}
        <Button sx={buttonStyles} onClick={Register}>Зарегистрироваться</Button>
    </div>
}