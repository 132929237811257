import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useState} from "react";
import style from "./CreateChildModal.module.css"
import {useModal} from "../../../../shared/hooks/useModal";
import {useAppData} from "../../../../shared/hooks/useAppData";

export const CreateChildModal = () => {
    const {modal} = useModal()
    const {createNewChild} = useAppData()
    const [login, setLogin] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isMan, setIsMan] = useState(true);

    return <div className={style.wrapper}>
        <TextField
            size="small"
            sx={{flex: "1"}}
            label={"Логин"}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
        />
        <TextField
            size="small"
            sx={{flex: "1"}}
            label={"Фамилия"}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
            size="small"
            sx={{flex: "1"}}
            label={"Имя"}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
            size="small"
            sx={{flex: "1"}}
            label={"Отчество"}
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
        />
        <FormControl size="small" sx={{ marginTop: "10px" }}>
            <InputLabel id="select-label">Человек?</InputLabel>
            <Select
                labelId="select-label"
                label="Человек?"
                value={isMan ? 'Да' : 'Нет'}
                onChange={(e) => setIsMan(e.target.value === 'Да')}
            >
                <MenuItem value="Да">Да</MenuItem>
                <MenuItem value="Нет">Нет</MenuItem>
            </Select>
        </FormControl>
        <div className={style.buttonWrapper}>
            <Button
                sx={{
                    marginTop: "10px",
                    color: "white",
                    backgroundColor: "grey",
                    '&:hover': {
                        backgroundColor: "darkgrey",
                    },
                }}
                onClick={() => modal.close()}
            >Отмена
            </Button>
            <Button
                sx={{
                    marginTop: "10px",
                    color: "white",
                    backgroundColor: "grey",
                    '&:hover': {
                        backgroundColor: "darkgrey",
                    },
                }}
                onClick={() =>
                    createNewChild(login, firstName, middleName, middleName, isMan)
                }
            >Создать
            </Button>
        </div>
    </div>
}