import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const ResetPasswordComp = () => {
    const history = useNavigate()

    return <div>
        HUI
        <Button onClick={() => history("/login")}>Назад</Button>
    </div>
}