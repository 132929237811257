import style from "../Login.module.css";
import {Button, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {useAppData} from "../../../../shared/hooks/useAppData";
import {loginUser} from "../../api/request";

export const LoginComp = ({buttonStyles, inputStyles}) => {
    const {getUserInfoSuccessHandler} = useAppData()
    const history = useNavigate()
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState({})

    const emptyCheck = () => {
        const newErrors = {}
        if (!login) {
            newErrors.login = "Логин обязателен"
        }
        if (!password) {
            newErrors.password = "Пароль обязателен"
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const clearFields = () => {
        setLogin("")
        setPassword("")
    }

    const Login = () => {
        if (emptyCheck()) {
            loginUser(login, password, history, clearFields, getUserInfoSuccessHandler)
        }
    }

    return <div>
        <div className={style.header}>Вход в личный кабинет</div>
        <div className={style.inputField}>
            <TextField
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                size="small"
                error={!!errors.login}
                label={"Логин"}
                sx={inputStyles}
            />
        </div>
        <div className={style.inputField}>
            <TextField
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="small"
                error={!!errors.password}
                label={"Пароль"}
                sx={inputStyles}
            />
        </div>
        <Button sx={buttonStyles} onClick={Login}>Войти</Button>
    </div>
}