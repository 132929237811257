import {sendGetMsg, sendPostMsg} from "../../../shared/api/SendMsg";
import {URLS} from "../../../shared/const/Backend";

export const createChild = (login, firstName, lastName, middleName, isMan, successHandler) => {
    sendPostMsg(URLS.USER + "/child",
        {
            "login": login,
            "firstName": firstName,
            "lastName": lastName,
            "middleName": middleName,
            "isMan": isMan,
        },
        successHandler
    )
}

export const testRequest = (text) => {
    sendGetMsg("test/ws-test?msg=" + text)
}

