import style from "./UserData.module.css"
import {Button, TextField} from "@mui/material";
import {logout} from "../../main/api/request";
import {useNavigate} from "react-router-dom";
import {useAppData} from "../../../shared/hooks/useAppData";
import {useEffect, useState} from "react";
import {FirstPart} from "./parts/FirstPart";
import {SecondPart} from "./parts/SecondPart";
import {ThirdPart} from "./parts/ThirdPart";

export const UserData = () => {
    const history = useNavigate()
    const {userInfo, updateUserData, setUserInfo} = useAppData()
    const [curUserData, setCurUserData] = useState({
        lastName: '',
        firstName: '',
        middleName: '',
        isMan: true,
        login: '',
        phone: '',
        email: '',
        ...userInfo
    })
    const [defUserData, setDefUserData] = useState(curUserData)

    useEffect(() => {
        if (userInfo) {
            const updatedData = {
                lastName: '',
                firstName: '',
                middleName: '',
                isMan: true,
                login: '',
                phone: '',
                email: '',
                ...userInfo
            }
            setCurUserData(updatedData)
            setDefUserData(updatedData)
        }
    }, [userInfo])

    const handleChange = (field, value) => {
        setCurUserData(prevState => ({
            ...prevState,
            [field]: value
        }))
    }

    const handleCancelChanges = () => {
        setCurUserData(userInfo)
    }

    const hasChanges = JSON.stringify(curUserData) !== JSON.stringify(defUserData)

    return <div className={style.wrapper}>
        <div className={style.topLine}>
            <FirstPart
                curUserData={curUserData}
                handleChange={handleChange}
            />
            <SecondPart
                curUserData={curUserData}
                handleChange={handleChange}
            />
            <ThirdPart/>
        </div>
        <div className={style.botLine}>
            <div className={style.botLineWrapper}>
                <div className={style.leftPart}>
                    <div className={style.botText}>Баланс</div>
                    <TextField size="small" />
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "green",
                            color: "white",
                            marginLeft: "20px"
                        }}
                    >Пополнить
                    </Button>
                </div>
                <div className={style.rightPart}>
                    {hasChanges && (
                        <>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "red",
                                    color: "white"
                                }}
                                onClick={handleCancelChanges}
                            >Отменить изменения
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "green",
                                    color: "white",
                                    marginLeft: "20px"
                                }}
                                onClick={() => {
                                    updateUserData(curUserData)
                                }}
                            >Сохранить изменения
                            </Button>
                        </>
                    )}
                    <Button
                        onClick={() => {
                            logout()
                            history("/")
                            setUserInfo(null)
                        }}
                        variant="contained"
                        sx={{
                            backgroundColor: "grey",
                            marginLeft: "20px",
                            marginRight: "40px"
                        }}
                    >Выйти
                    </Button>
                </div>
            </div>
        </div>
    </div>
}