import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from "./app/ui/App";
import {BrowserRouter} from "react-router-dom";
import {AppDataProvider} from "./shared/hooks/useAppData";
import {ModalDataProvider} from "./shared/hooks/useModal";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
          <AppDataProvider>
              <ModalDataProvider>
                  <App/>
              </ModalDataProvider>
          </AppDataProvider>
      </BrowserRouter>
);
