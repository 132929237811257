import style from "../UserData.module.css";
import {Button, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip} from "@mui/material";
import {useAppData} from "../../../../shared/hooks/useAppData";
import {useModal} from "../../../../shared/hooks/useModal";
import {CreateChildModal} from "./CreateChildModal";

export const FirstPart = ({curUserData, handleChange}) => {
    const {familyData} = useAppData()
    const {modal} = useModal()

    return <div className={`${style.firstPart} ${style.parts}`}>
        <div className={style.inputField}>
            <div className={style.inputText}>Фамилия</div>
            <TextField
                size="small"
                sx={{flex: "1"}}
                value={curUserData.lastName}
                onChange={(e) => handleChange('lastName', e.target.value)}
            />
        </div>
        <div className={style.inputField}>
            <div className={style.inputText}>Имя</div>
            <TextField
                size="small"
                sx={{flex: "1"}}
                value={curUserData.firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
            />
        </div>
        <div className={style.inputField}>
            <div className={style.inputText}>Отчество</div>
            <TextField
                size="small"
                sx={{flex: "1"}}
                value={curUserData.middleName}
                onChange={(e) => handleChange('middleName', e.target.value)}
            />
        </div>
        <div className={style.inputField}>
            <div className={style.inputText}>Пол</div>
            <Select
                size="small"
                sx={{flex: "1"}}
                value={curUserData.isMan ? 'М' : 'Ж'}
                onChange={(e) => handleChange('isMan', e.target.value === 'М')}
            >
                <MenuItem value="М">М</MenuItem>
                <MenuItem value="Ж">Ж</MenuItem>
            </Select>
        </div>
        <div className={style.radioGroupWrapper}>
            <RadioGroup row value="Специалист">
                <FormControlLabel
                    value="Родитель"
                    control={<Radio size="small"/>}
                    label="Родитель"
                />
                <FormControlLabel
                    value="Специалист"
                    control={<Radio size="small"/>}
                    label="Специалист"
                />
            </RadioGroup>
        </div>
        <div className={style.inputField}>
            <div className={style.inputText}>Логин</div>
            <TextField
                size="small"
                sx={{flex: "1"}}
                value={curUserData.login}
                onChange={(e) => handleChange('login', e.target.value)}
            />
        </div>
        <div className={style.radioGroupWrapper}>
            <Button
                variant="contained"
                sx={{
                    width: "224px",
                    backgroundColor: "grey"
                }}
            >Сменить пароль
            </Button>
        </div>
        <div className={style.childWrapper}>
            {familyData && familyData.members.map((member) => {
                if (member.role === "CHILD") {
                    return <div key={member.id} className={style.childView}>
                        {member.firstName}
                    </div>
                }
                return null
            })}
            <Tooltip title={"Добавить ребенка"}>
                <Button
                    onClick={() => modal.open(<CreateChildModal/>)}
                    variant="contained"
                    sx={{
                        backgroundColor: "grey",
                        marginLeft: "20px",
                        marginRight: "40px"
                    }}
                >+
                </Button>
            </Tooltip>
        </div>
    </div>
}