import style from "./Main.module.css"
import {ICONS} from "../../../shared/lib/img/icons/icons";
import {Button} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {UserData} from "../../userData/ui/UserData";
import {useAppData} from "../../../shared/hooks/useAppData";

export const Main = () => {
    const {familyData} = useAppData()
    const history = useNavigate()
    const {mode} = useParams()
    const {userInfo, isLogin} = useAppData()

    const buttonStyles = {
        backgroundColor: "#fffff",
        color: '#1a1919',
        width: "180px",
        fontSize: "12px",
        height: "50px",
        border: "1px solid #1a1919",
        borderRadius: 0,
        margin: "10px",
        '&:hover': {
            backgroundColor: "lightgrey",
        },
    }

    return <div className={style.wrapper}>
        <div className={style.header}>
            <div className={style.headerLeft}>
                <div className={style.mainIcon}>
                    {ICONS.kotPhone}
                </div>
                <div className={style.groupButtons}>
                    <Button sx={buttonStyles} onClick={() => history("/about-child")}>О ребёнке</Button>
                    <Button sx={buttonStyles}>Конструктор зянятия</Button>
                    <Button sx={buttonStyles}>Статьи</Button>
                    <Button sx={buttonStyles}>О нас</Button>
                </div>
            </div>
            <div className={style.headerRight}>
                <div className={style.mainIcon}>{ICONS.rabbit}</div>
                <div className={style.textWrapper}>
                    <span className={style.text}>{userInfo && userInfo["lastName"]}</span>
                    <span className={style.text}>{userInfo && userInfo["firstName"]}</span>
                    <span className={style.text}>{userInfo && userInfo["middleName"]}</span>
                </div>
                <div onClick={() => {
                    isLogin ? history("/user-data") : history("/login")
                }}>
                    {ICONS.kot}
                </div>
            </div>
        </div>
        {mode === "user-data" && <UserData/>}
        {mode === "about-child" &&
            <div className={style.aboutChild}>
                {familyData && familyData.members.map((member) => {
                    if (member.role === "CHILD") {
                        return <div key={member.id}>
                            <div className={style.groupIcons}>
                                <div>{ICONS.kot}</div>
                                <div className={style.iconText}>{member.firstName}</div>
                            </div>
                        </div>
                    }
                    return null
                })}
            </div>
        }
    </div>
}