import style from "./Login.module.css"
import {bgImage, ICONS} from "../../../shared/lib/img/icons/icons";
import {useNavigate, useParams} from "react-router-dom";
import {LoginComp} from "./parts/LoginComp";
import {RegisterComp} from "./parts/RegisterComp";
import {ResetPasswordComp} from "./parts/ResetPasswordComp";

export const Login = () => {
    const history = useNavigate()
    const {tab} = useParams()
    const buttonStyles = {
        marginTop: "15px",
        width: "100%",
        backgroundColor: "#68914F",
        borderRadius: '25px',
        color: 'white',
        '&:hover': {
            backgroundColor: "#e68900",
            color: '#1a1919'
        },
    }

    const inputStyles = {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            right: 0,
            borderRadius: '25px',
            borderColor: 'green',
        },
        '& .MuiInputLabel-root': {
            color: 'green',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'green',
        },
    }

    const array = new Map()
    array.set("register", <RegisterComp buttonStyles={buttonStyles} inputStyles={inputStyles}/>)
    array.set("", <LoginComp inputStyles={inputStyles} buttonStyles={buttonStyles} history={history}/>)
    array.set("reset-password", <ResetPasswordComp buttonStyles={buttonStyles}/>)

    return <div className={style.wrapper} style={{backgroundImage: `url(${bgImage})`}}>
        <div className={style.topLine}>
            {ICONS.kotPhone}
            <div className={style.topLineText}>
                VALERA
            </div>
        </div>
        <div className={style.container}>
            <div className={style.box}>
                {array.has(tab) ? array.get(tab) : array.get("")}
            </div>
            <div className={style.links}>
                <div onClick={() => history("/login/reset-password")} className={style.link}>Я забыл(а) пароль</div>
                {tab === "register"
                    ?
                    <div onClick={() => history("/login")} className={style.link}>У меня есть аккаунт</div>
                    :
                    <div onClick={() => history("/login/register")} className={style.link}>У меня нет аккаунта</div>
                }
            </div>
        </div>
        <div className={style.bottomLine}>
            Создано для ДЦП и Аутистов командой профессионалов в 2024г.
        </div>
    </div>
}
