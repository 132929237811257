import './App.module.css';
import style from "./App.module.css"
import {Login} from "../../pages/login";
import {Route, Routes} from "react-router-dom";
import {Main} from "../../pages/main/ui/Main";
import {Toaster} from "react-hot-toast";
import {ModalWindow} from "../../shared/ui/ModalWindow/ModalComp";

export const App = () => {
    return <div className={style.wrapper}>
        <Toaster/>
        <ModalWindow/>
        <Routes>
            <Route path={"/"} element={<Main/>}/>
            <Route path={"/:mode"} element={<Main/>}/>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/login/:tab"} element={<Login/>}/>
        </Routes>
    </div>
}