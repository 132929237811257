import {createContext, useContext, useEffect, useState} from "react";
import {getFamily, getUserInfo, updateUser} from "../../app/api/request";
import {createChild} from "../../pages/userData/api/request";

const AppDataContext = createContext(null);

export const useAppData = () => {
    return useContext(AppDataContext);
}

export const AppDataProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(null)
    const [isLogin, setIsLogin] = useState(false)
    const [familyData, setFamilyData] = useState(null)

    useEffect(() => {
        setIsLogin(!!userInfo)
    }, [userInfo])

    const getUserInfoSuccessHandler = (response) => {
        setUserInfo(response)
        if (response["familyId"] !== null) {
            getFamily(setFamilyData)
        }
    }

    const refreshUserInfo = () => {
        getUserInfo(getUserInfoSuccessHandler)
    }

    const updateUserData = (newData) => {
        updateUser(newData, setUserInfo)
    }

    const createNewChild = (login, firstName, lastName, middleName, isMan) => {
        createChild(login, firstName, lastName, middleName, isMan, refreshUserInfo)
    }

    useEffect(() => {
        refreshUserInfo()
        // eslint-disable-next-line
    }, [])

    const combinedData = {
        isLogin,
        updateUserData,
        createNewChild,
        userInfo,
        setUserInfo,
        familyData,
        getUserInfoSuccessHandler,
    }

    return<AppDataContext.Provider value={combinedData}>{children}</AppDataContext.Provider>
}