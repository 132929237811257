import {DEBUG, SERVER_ADDRESS} from "../const/Backend";

export const sendMsg = (
    method,
    destination,
    bodyObj,
    successHandler = () => {},
    errorHandler = () => {},
) => {
    let isBodyJson = bodyObj === null || (typeof bodyObj === 'object' && bodyObj.constructor === Object)

    // prepare url
    let url = SERVER_ADDRESS + "/" + destination

    // prepare headers
    const headers = new Headers()
    if (isBodyJson) headers.append("Content-Type", "application/json")

    // result status reactions
    const statusActions = new Map()
    statusActions.set(200, (rs) => {
        rs.code === 0 ? successHandler(rs.data) : errorHandler(rs.message)
    })

    const answerIsJson = (rs) => {
        if (DEBUG) console.log("<< Response JSON (" + rsStatus + ")", rs)
        try {
            statusActions.get(rsStatus)(rs)
        } catch (err) {
            console.error("Error while execute Response Handler for status: " + rsStatus, err)
        }
    }

    // handler if answer is text
    const answerIsText = (text) => {
        if (DEBUG) {
            if (rsStatus === 200) console.log("<< Response TEXT (" + rsStatus + ")", text)
            else console.error("<< Response TEXT (" + rsStatus + ")", text)
        }

        statusActions.get(rsStatus)(text)
    }

    const answerIsBlob = (content) => {
        const imageUrl = URL.createObjectURL(content)

        if (DEBUG) console.log("<< Response BLOB (" + rsStatus + ")", imageUrl)
        statusActions.get(rsStatus)(imageUrl)
    }

    // fetch
    if (DEBUG) console.warn(">> Send message [" + method + "] " + url, method === "GET" ? "" : bodyObj)
    let rsStatus = 0;
    let rsContentType = "";
    fetch(url,
        method === "GET"
            ? { method: method, headers: headers, credentials: 'include'}
            : { method: method,
                headers: headers,
                credentials: 'include',
                body: isBodyJson ? JSON.stringify(bodyObj) : bodyObj}
    )
        .then((response) => {
            rsStatus = response.status
            rsContentType = response.headers.get("Content-Type")
            if (rsContentType === "application/json") {
                return response.json()
            }
            if (rsContentType === "application/octet-stream") {
                return response.blob()
            }
            return response.text()})
        .then((response) => {
            switch (rsContentType) {
                case "application/json":
                    answerIsJson(response)
                    break;
                case "application/octet-stream":
                    answerIsBlob(response)
                    break;
                default:
                    answerIsText(response)
            }
        }).catch( e => {
        errorHandler("<< ERROR" + e)
    });
}

export function sendGetMsg(destination, bodyObj, successHandler, errorHandler) {
    sendMsg("GET", destination, bodyObj, successHandler, errorHandler) }
export function sendPostMsg(destination, bodyObj, successHandler, errorHandler) {
    sendMsg("POST", destination, bodyObj, successHandler, errorHandler) }
export function sendPutMsg(destination, bodyObj, successHandler, errorHandler) {
    sendMsg("PUT", destination, bodyObj, successHandler, errorHandler) }
// export function sendDeleteMsg(destination, bodyObj, successHandler, errorHandler) {
//     sendMsg("DELETE", destination, bodyObj, successHandler, errorHandler) }