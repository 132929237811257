import {sendPostMsg} from "../../../shared/api/SendMsg";
import {URLS} from "../../../shared/const/Backend";
import toast from "react-hot-toast";

export const registerUser = (login, password, role, specId, history, clearFields) => {
    sendPostMsg(URLS.AUTH,
        {
            "login": login,
            "password": password,
            "role": role,
            "specId": specId,
        },
        () => {
            history("/login")
            clearFields()
            toast.success("Вы успешно зарегистрировались!")
        }
    )
}

export const loginUser = (login, password, history, clearFields, getUserInfoSuccessHandler) => {
    sendPostMsg(URLS.AUTH + "/login",
        {
            "login": login,
            "password": password
        },
        (response) => {
            history("/")
            clearFields()
            getUserInfoSuccessHandler(response)
        }
    )
}