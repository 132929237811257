import style from "../UserData.module.css";
import {Button, TextField} from "@mui/material";
import {testRequest} from "../../api/request";
import {useState} from "react";

export const SecondPart = ({curUserData, handleChange}) => {
    const [text, setText] = useState("")

    return <div className={`${style.secondPart} ${style.parts}`}>
        <div className={style.inputField}>
            <div className={style.inputText}>Телефон</div>
            <TextField
                sx={{flex: "1"}}
                size="small"
                value={curUserData.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
            />
        </div>
        <div className={style.inputField}>
            <div className={style.inputText}>Почта</div>
            <TextField
                sx={{flex: "1"}}
                size="small"
                value={curUserData.email}
                onChange={(e) => handleChange('email', e.target.value)}
            />
        </div>
        <div>
            <TextField
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Button onClick={() => testRequest(text)}>
                testButton
            </Button>
        </div>
    </div>
}