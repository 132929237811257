import style from "../UserData.module.css";
import {ICONS} from "../../../../shared/lib/img/icons/icons";

export const ThirdPart = () => {
    return <div className={`${style.thirdPart} ${style.parts}`}>
        <div className={style.inputField}>
            <div className={style.inputText}>ID семьи</div>
            <div className={style.textValue}>#555</div>
        </div>
        <div className={style.inputField}>
            <div className={style.inputText}>ID пользователя</div>
            <div className={style.textValue}>#2607</div>
        </div>
        <div className={style.photo}>
            {ICONS.kotPhone}
            <div className={style.updatePhoto}>Обновить фото</div>
        </div>
    </div>
}